import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import JobList from "./components/jobs-tables/JobList";
import JobDetailsPage from "./components/job-details/JobDetailsPage";
import Home from "./components/home";
import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import JobsSearchPage from "./components/jobs-search/JobsSearchPage";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<Home/>}/>
                <Route path="/jobs/:jobName" element={<JobList/>}/>
                <Route path="/job/:jobId" element={<JobDetailsPage/>}/>
                <Route path="/search" element={<JobsSearchPage/>}/>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);
