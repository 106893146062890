import {Job} from "../model/Job";
import {JOB_STATUS} from "./job_enums";

interface ColorMapper {
    map(job: Job): string;
}

class SlaColorMapper implements ColorMapper {
    map(job: Job): string {
        if (job.slaRisk < 0) {
            return "black";
        }

        return `hsl(${(120 / job.sla) * job.slaRisk}, 75%, 50%)`;
    }
}

class RuntimeColorMapper implements ColorMapper {

    private readonly colorLine: { m: number, b: number };

    /**
     * Calculate colors for runtime values by mapping them to a line y = mx + b in 2d space formed by two points:
     * (minimum value in the table, 120) and (30 minutes, 0).
     */
    constructor(tableData: any[]) {
        const runtimes = tableData.filter(f => f instanceof Job && f.runtimeSeconds > 0).map(f => f.runtimeSeconds);

        const minRuntime = Math.min.apply(null, runtimes);

        const m = (0 - 120) / ((30 * 60) - minRuntime);
        const b = -m * (30 * 60);

        this.colorLine = {m, b};
    }

    map(job: Job): string {
        if (job.runtimeSeconds > 30 * 60) {
            return `black`;
        }

        return `hsl(${this.colorLine.m * job.runtimeSeconds + this.colorLine.b}, 75%, 50%)`;
    }

}

class NoColorMapper implements ColorMapper {
    map(job: Job): string {
        return ``;
    }
}

class WaitForDependenciesColorMapper implements ColorMapper {

    private readonly colorLine: { m: number, b: number };

    /**
     * Calculate colors for wait for dependencies values by mapping them to a line y = mx + b in 2d space formed by two points:
     * (minimum value in the table, 120) and (120 minutes, 0).
     */
    constructor(tableData: any[]) {
        const waitTimes = tableData.filter(f => f instanceof Job && f.waitForDependenciesSeconds >= 0).map(f => f.waitForDependenciesSeconds);

        const minWaitTime = Math.min.apply(null, waitTimes);

        const m = (0 - 120) / ((120 * 60) - minWaitTime);
        const b = -m * (120 * 60);

        this.colorLine = {m, b};
    }

    map(job: Job): string {
        if (job.waitForDependenciesSeconds > 120 * 60) {
            return `black`;
        }

        return `hsl(${this.colorLine.m * job.waitForDependenciesSeconds + this.colorLine.b}, 75%, 50%)`;
    }
}

class WaitForResourcesColorMapper implements ColorMapper {

    private readonly colorLine: { m: number, b: number };

    /**
     * Calculate colors for wait for resources values by mapping them to a line y = mx + b in 2d space formed by two points:
     * (minimum value in the table, 120) and (5 minutes, 0).
     */
    constructor(tableData: any[]) {
        const waitTimes = tableData.filter(f => f instanceof Job && f.waitForResourcesSeconds >= 0).map(f => f.waitForResourcesSeconds);

        const minWaitTime = Math.min.apply(null, waitTimes);

        const m = (0 - 120) / ((5 * 60) - minWaitTime);
        const b = -m * (5 * 60);

        this.colorLine = {m, b};
    }

    map(job: Job): string {
        if (job.waitForResourcesSeconds > 5 * 60) {
            return `black`;
        }

        return `hsl(${this.colorLine.m * job.waitForResourcesSeconds + this.colorLine.b}, 75%, 50%)`;
    }
}

export class ColorMapperContext {

    private readonly colorMapper: ColorMapper;

    constructor(tableData: any[], displayType: string) {
        switch (displayType) {
            case "SLA_RISK":
                this.colorMapper = new SlaColorMapper();
                break;
            case "RUN_TIME":
                this.colorMapper = new RuntimeColorMapper(tableData);
                break;
            case "WAIT_FOR_DEPENDENCIES_TIME":
                this.colorMapper = new WaitForDependenciesColorMapper(tableData);
                break;
            case "WAIT_FOR_RESOURCES_TIME":
                this.colorMapper = new WaitForResourcesColorMapper(tableData);
                break;
            default:
                this.colorMapper = new NoColorMapper();
                break;
        }
    }

    color(value: Job | string): string {
        if (!value || (value as Job).jobStatus !== JOB_STATUS.DONE) {
            return new NoColorMapper().map(value as Job);
        }

        return this.colorMapper.map(value as Job);
    }
}